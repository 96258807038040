
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { formatDate } from "@/utils/formatters";

  export interface MyWorkActivityRowColumn {
    showInHeader?: boolean;
    type: "name" | "work_status" | "column_expander" | "legal_documents_uploaded" | "office_name" | "task_count" | "date" | "user";
    label?: string;
    width?: string;
    center?: boolean;
    headerCenter?: boolean;
  }

  @Component({
    components: {
      GeneralItemRow: () => import("@/components/general/item-row.vue"),
      GeneralStatusLabel: () => import("@/components/general/status-label.vue"),
      UserImage: () => import("@/components/general/user-image.vue"),
      UserImageWrapper: () => import("@/components/general/user-image-wrapper.vue"),
      ItemRowColorLabel: () => import("@/components/general/item-row-color-label.vue"),
      WorkStatusTag: () => import("@/components/dossier/work-status-tag.vue"),
    },
  })
  export default class MyWorkActivityRow extends Vue {
    @Prop() activity!: MyWorkActivity;
    @Prop() date!: string;
    @Prop() user!: { first_name: string; last_name: string; media: Media | null; preview_url: string | null };
    @Prop() isOpen?: boolean;
    @Prop() isLoading?: boolean;
    @Prop() color?: string;
    @Prop() defaultDate?: string;
    @Prop() label?: MyWorkActivityActionLabel;

    @Prop() columns!: MyWorkActivityRowColumn[];

    formatDate = formatDate;

    generateTooltip(content: string) {
      return { content: content, offset: [0, 12], delay: { show: 500, hide: 100 }, placement: "top" };
    }
  }
